<template>
  <div>
    <el-dialog
      title="一键赋值"
      :visible.sync="dialogVisible"
      width="500px"
      center
      :close-on-click-modal="false"
    >
      <el-form ref="queryForm" label-position="center" label-width="150px" :model="queryForm" :rules="rules">
        <el-form-item label="采购订单" prop="purchaseOrderCode">
          <Select
            v-model="queryForm.purchaseOrderCode"
            :select-options="poList"
            :configuration="optionsConfig"
            multiple
          />
        </el-form-item>
        <el-form-item label="调整后单价（含税)" prop="changeAfterHaveTaxPrice">
          <el-input-number
            v-model="queryForm.changeAfterHaveTaxPrice"
            :controls="false"
            :precision="2"
            :min="0.01"
            @blur="handleSamePrice(queryForm)"
          />
        </el-form-item>

      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="$emit('input', false)">取消</el-button>
        <el-button type="primary" @click="submit">保存</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>

import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import Select from '@/components/Selection'
import { FloatSub } from '@/utils/acc'

export default {

  components: { Select },
  mixins: [commodityInfoDict],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    poList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      optionsConfig: { key: 'purchaseOrderCode', label: 'purchaseOrderCode', value: 'purchaseOrderCode' },
      queryForm: { purchaseOrderCode: [], changeAfterHaveTaxPrice: undefined },
      rules: {
        changeAfterHaveTaxPrice: [{ required: true, message: '必填', trigger: 'blur' }],
        purchaseOrderCode: [{ required: true, message: '必填', trigger: 'change' }]

      }
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }

  },
  watch: {
    'dialogVisible'(val) {
      if (!val) {
        Object.assign(this.queryForm, this.$options.data.call(this).queryForm)
      } else {
        if (this.poList.length === 1) {
          this.queryForm.purchaseOrderCode = [this.poList[0].purchaseOrderCode]
        }
      }
      this.$refs.queryForm && this.$refs.queryForm.clearValidate()
    }
  },
  mounted() {
  },
  methods: {
    handleSamePrice({ changeAfterHaveTaxPrice, purchaseOrderCode }) {
      const beforeHaveTaxPriceList = []
      purchaseOrderCode.map(item => {
        const changePriceBillOrderDetailSaveDtoList = this.poList.find(e => e.purchaseOrderCode === item)?.changePriceBillOrderDetailSaveDtoList
        changePriceBillOrderDetailSaveDtoList.map(ee => beforeHaveTaxPriceList.push(ee.changeBeforeHaveTaxPrice))
      })
      if (beforeHaveTaxPriceList.includes(changeAfterHaveTaxPrice)) {
        this.$message.warning('调整后单价不能与调整前单价相等')
        this.$set(this.queryForm, 'changeAfterHaveTaxPrice', undefined)
        return
      }
    },
    submit() {
      this.$refs.queryForm.validate(async(valid) => {
        // 需要重新计算幅度
        if (valid) {
          const { purchaseOrderCode, changeAfterHaveTaxPrice } = this.queryForm
          purchaseOrderCode.map(item => {
            const obj = this.poList.find(e => e.purchaseOrderCode === item)
            if (obj.changePriceBillOrderDetailSaveDtoList) {
              this.$set(obj, 'changePriceBillOrderDetailSaveDtoList', obj.changePriceBillOrderDetailSaveDtoList.map(e => {
                const changeRange = FloatSub(changeAfterHaveTaxPrice, e.changeBeforeHaveTaxPrice)
                return { ...e, changeAfterHaveTaxPrice,
                  changeRange: (changeRange < 0 ? '减少' : '增加') + Math.abs(changeRange)
                }
              }))
            }
          })
          this.$emit('handleOneClick', this.poList)
          this.$emit('input', false)
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
/deep/.el-input  .el-input__inner {
    width: 100%!important;
}
</style>
