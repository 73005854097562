import request from '@/utils/scm-request'

export function priceBillPage(params) {
  return request({
    url: `scm-purchase/ChangePriceBill/page`,
    method: 'get',
    params
  })
}

export function priceBillDetailPage(params) {
  return request({
    url: `scm-purchase/ChangePriceBill/pageDetail`,
    method: 'get',
    params
  })
}

export function changePriceBillImportView(data) {
  return request({
    url: `scm-purchase/ChangePriceBill/changePriceBillImportView`,
    method: 'post',
    data
  })
}

export function changePriceBillImport(data) {
  return request({
    url: `scm-purchase/ChangePriceBill/changePriceBillImport`,
    method: 'post',
    data
  })
}

export function cancel(data) {
  return request({
    url: `scm-purchase/ChangePriceBill/cancel`,
    method: 'post',
    data
  })
}

//
export function getChangePriceBillInfo(params) {
  return request({
    url: `scm-purchase/ChangePriceBill/getChangePriceBillInfo`,
    method: 'get',
    params
  })
}

export function getCanChangePricePurchaseOrderInfo(data) {
  return request({
    url: `scm-purchase/ChangePriceBill/getCanChangePricePurchaseOrderInfo`,
    method: 'post',
    data
  })
}

export function save(data) {
  return request({
    url: `scm-purchase/ChangePriceBill/save`,
    method: 'post',
    data
  })
}

export function submit(data) {
  return request({
    url: `scm-purchase/ChangePriceBill/submit`,
    method: 'post',
    data
  })
}

export function listEditAgreementCode(params) {
  return request({
    url: `scm-purchase/ChangePriceBill/listEditAgreementCode`,
    method: 'get',
    params
  })
}

export function saveEditAgreementCode(data) {
  return request({
    url: `scm-purchase/ChangePriceBill/saveEditAgreementCode`,
    method: 'post',
    data
  })
}

export function editAgreement(data) {
  return request({
    url: `scm-purchase/ChangePriceBill/editAgreement`,
    method: 'post',
    data
  })
}

export function submitEditAgreementCode(data) {
  return request({
    url: `scm-purchase/ChangePriceBill/submitEditAgreementCode`,
    method: 'post',
    data
  })
}

export function getPoColorSizeMap(params) {
  return request({
    url: `scm-purchase/order/getPoColorSizeMap`,
    method: 'get',
    params
  })
}

export function saveGenerateAgreementCode(data) {
  return request({
    url: `scm-purchase/ChangePriceBill/saveGenerateAgreementCode`,
    method: 'post',
    data
  })
}
