<template>
  <div>
    <el-dialog
      title="选择采购订单"
      :visible.sync="dialogVisible"
      width="500px"
      center
      :close-on-click-modal="false"
    >
      <el-form ref="queryForm" label-position="center" label-width="150px" :model="queryForm" :rules="rules">
        <el-form-item label="采购订单" prop="purchaseOrderCode">
          <el-input
            v-model="queryForm.purchaseOrderCode"
            :placeholder="$t('page.inputPlaceholder')"
            @keyup.enter.native="blurHandle"
            @blur="blurHandle"
          />
        </el-form-item>
        <el-form-item label="Style" prop="style">
          <Select
            v-model="queryForm.style"
            style="width:100%;"
            api-key="styleList"
            clearable
            disabled
            :configuration="{ key: 'id', label: 'styleName', value: 'styleName' }"
            @responseData="val=>styleList=val"
          />
        </el-form-item>
        <el-form-item label="供应商" prop="vendorId">
          <Select
            v-model="queryForm.vendorId"
            disabled
            style="width:100%;"
            api-key="vendorList"
            clearable
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="$emit('input', false)">取消</el-button>
        <el-button type="primary" :loading="checkLoading" @click="submit">保存</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>

import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import Select from '@/components/Selection'
import { getCanChangePricePurchaseOrderInfo } from '@/api/adjust-price'

export default {

  components: { Select },
  mixins: [commodityInfoDict],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    currentData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      styleList: [],
      checkLoading: false,
      queryForm: { vendorId: '', style: '', styleId: '', purchaseOrderCode: '' },
      rules: {
        style: [{ required: true, message: '必填', trigger: 'change' }],
        purchaseOrderCode: [{ required: true, message: '必填', trigger: 'blur' }],
        vendorId: [{ required: true, message: '必填', trigger: 'change' }]
      }
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }

  },
  watch: {
    'dialogVisible'(val) {
      if (!val) {
        Object.assign(this.queryForm, this.$options.data.call(this).queryForm)
        this.principalList = []
      }
      this.$refs.queryForm && this.$refs.queryForm.clearValidate()
    }
  },
  mounted() {
  },
  methods: {
    async blurHandle() {
      this.queryForm.style = ''
      this.queryForm.vendorId = ''
      const { purchaseOrderCode } = this.queryForm
      const { changePriceBillCode = '' } = this.currentData
      if (purchaseOrderCode.trim()) {
        const { datas: { style, vendorId }} = await getCanChangePricePurchaseOrderInfo({ purchaseOrderCode, changePriceBillCode })
        Object.assign(this.queryForm, { style, styleId: this.styleList.find(item => item.styleName === style)?.id }, { vendorId })
      }
    },
    goBatchAdd({ purchaseOrderCode, style, changePriceBillCode }) {
      this.checkLoading = true
      this.$emit('input', false)
      this.$router.push({
        path: 'batch-add',
        append: 'true',
        query: { purchaseOrderCode, style, changePriceBillCode }})
    },
    submit() {
      this.$refs.queryForm.validate(async(valid) => {
        if (valid) {
          try {
            // 存现有数据
            const { changePriceBillCode = '' } = this.currentData
            const { purchaseOrderCode, vendorId } = this.queryForm
            this.queryForm.changePriceBillCode = changePriceBillCode

            const arr = this.currentData.changePriceBillOrderList.find(item => item.purchaseOrderCode === purchaseOrderCode)?.changePriceBillOrderDetailSaveDtoList
            if (this.currentData.changePriceBillOrderList.some(item => item.vendorId !== vendorId)) {
              return this.$message.error('新添加的采购订单需与当前已经添加的采购订单为同一供应商')
            }
            if (arr && arr.length) {
              this.$confirm('当前采购订单已存在调价明细，是否继续添加？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
              }).then(() => {
                sessionStorage.setItem('currentAdjustData', JSON.stringify(this.currentData))
                this.goBatchAdd(this.queryForm)
              })
            } else {
              sessionStorage.setItem('currentAdjustData', JSON.stringify(this.currentData))
              this.goBatchAdd(this.queryForm)
            }
          } finally {
            this.checkLoading = false
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
/deep/.el-input  .el-input__inner {
    width: 100%!important;
}
</style>
