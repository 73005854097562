<template>
  <div class="app-container">
    <el-row class="grid-content bg-purple-dark">
      <el-col :span="20" class="bg-purple-title">调价基本信息</el-col>
    </el-row>
    <el-form ref="Form" :model="supplierform" label-width="120px">
      <el-row :span="24">
        <el-col :span="8">
          <el-form-item label="调价单号" prop="offerCode">
            <el-input v-model="supplierform.changePriceBillCode" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="供应商">
            <Select v-model="supplierform.vendorName" api-key="vendorList" clearable disabled />
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="状态">
            <Select
              v-model="supplierform.billStatus"
              :select-options="_getAllCommodityDict('CHANGE_PRICE_BILL_STATE')"
              :configuration="optionsConfig"
              clearable
              disabled
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="采购订单" prop="purchaseOrderCode">
            <div v-for="(item,index) in poList" :key="index" class="po-style">{{ item }}</div>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="成本工程师">
            <Select v-model="supplierform.costEngineerName" api-key="newUserList" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="调价发起时间">
            <el-input
              v-model="supplierform.changePriceLaunchTime"
              :placeholder="$t('page.inputPlaceholder')"
              disabled
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-row class="grid-content bg-purple-dark">
      <el-col :span="20">
        <span style="padding-left:25px">调价明细</span>
        <el-button type="primary" class="ml-2" :disabled="isView" @click="batchDialogVisible=true">批量新增</el-button>
        <el-button type="primary" :disabled="isView" @click="handleDelete()">删除</el-button>
        <el-button
          type="primary"
          :disabled="isView||!supplierform.changePriceBillOrderList.length"
          @click="oneClickDialogVisible=true"
        >一键赋值</el-button>
      </el-col>
    </el-row>
    <el-form ref="supplierform" :model="supplierform" :rules="rules">
      <Table
        ref="multipleTable"
        class="mt-4"
        :table-data="supplierform.changePriceBillOrderList"
        :columns="addAdjustcolumns"
        :show-expand-check="isView?false:true"
        :expand="true"
        :check="isView?false:true"
        :expand-columns="expandAdjustColumns"
        expand-data="changePriceBillOrderDetailSaveDtoList"
        @selectExpandData="selectExpandData"
        @select="select"
        @selectAll="selectAll"
        @handleTableExpandChange="handleTableExpandChange"
      >
        <el-table-column slot="adjustReason" label="调价原因" align="center" min-width="130" prop="changePriceReason">
          <template slot-scope="scope">
            <el-form-item :prop="'changePriceBillOrderList.' + scope.$index + '.changePriceReason'" :rules="rules.changePriceReason">
              <el-input
                v-model="scope.row.changePriceReason"
                type="textarea"
                maxlength="300"
                show-word-limit
                :disabled="isView"
              />
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column slot="remark" label="备注" align="center" min-width="130">
          <template slot-scope="scope">
            <el-form-item>
              <el-input v-model="scope.row.remark" type="textarea" maxlength="300" show-word-limit :disabled="isView" />
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column
          slot="afterAdjust"
          label="调整后单价（含税)"
          align="center"
          min-width="180"
        >
          <template slot-scope="scope">
            <el-form-item>
              <el-input-number
                v-model="scope.row.changeAfterHaveTaxPrice"
                :controls="false"
                :precision="2"
                :min="0.01"
                :disabled="isView"
                @change="handleChangeAfterHaveTaxPrice($event,scope.row)"
                @blur="handleSamePrice(scope.row)"
              />
            </el-form-item>
          </template>
        </el-table-column>

      </Table>
      <el-row class="grid-content bg-blue" type="flex" justify="center">
        <el-button v-if="isView" type="primary" @click="$router.go(-1)">返回</el-button>
        <el-button type="primary" :disabled="isView||!supplierform.changePriceBillOrderList.length" :loading="submitLoading" @click="onSubmit('save')">保存</el-button>
        <el-button type="primary" :disabled="isView||!supplierform.changePriceBillOrderList.length" :loading="submitLoading" @click="onSubmit('submit')">提交</el-button>
      </el-row>
    </el-form>
    <BatchAdd v-model="batchDialogVisible" :current-data="supplierform" />
    <OneClick
      v-model="oneClickDialogVisible"
      :po-list="supplierform.changePriceBillOrderList"
      @handleOneClick="handleOneClick"
    />

  </div>
</template>
<script>
import { getChangePriceBillInfo, save, submit } from '@/api/adjust-price'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import Select from '@/components/Selection'
import Table from '@/components/Table'
import { addAdjustcolumns, expandAdjustColumns } from '@/constant/tablecolumns'
import BatchAdd from './components/BatchAdd'
import OneClick from './components/OneClick'
import { FloatSub } from '@/utils/acc.js'

export default {
  components: { Select, Table, BatchAdd, OneClick },
  mixins: [commodityInfoDict],
  data() {
    return {
      oneClickDialogVisible: false,
      flag: '',
      submitLoading: false,
      save, submit, multipleSelectExpandTable: [], multipleSelectionTable: [],
      addAdjustcolumns, expandAdjustColumns,
      supplierform: {
        billStatus: '',
        changePriceLaunchTime: '',
        changePriceBillOrderList: []
      },
      batchDialogVisible: false,
      optionsConfig: { key: 'val', label: 'label', value: 'val' },
      poList: [''],
      rules: {
        changePriceReason: { required: true, message: '必填', trigger: 'blur' },
        changeAfterHaveTaxPrice: { required: true, message: '必填', trigger: 'blur' }
      }

    }
  },
  computed: {
    api() {
      return this.flag === 'save' ? save : submit
    },
    isView() {
      return this.$route.query.type === 'view'
    }
  },
  watch: {
    'editVisible'(val) {
      if (!val) {
        this.editForm = this.$options.data.call(this).editForm
        this.$refs['editForm'].resetFields()
      }
    },

    'supplierform.changePriceBillOrderList': {
      handler(val) {
        if (val) {
          this.poList = []
          if (val.length === 0) {
            this.supplierform.vendorName = ''
            this.poList = ['']
          }
          val.map(item => {
            this.poList.push(item.purchaseOrderCode)
            this.supplierform.vendorName = item.vendorName
            this.$refs.multipleTable.$refs.table.toggleRowSelection(
              item,
              item.changePriceBillOrderDetailSaveDtoList.every(e => e.checked)
            )
          })
        }
      },
      deep: true

    }
  },
  mounted() {
    this.$router.beforeEach((to, from, next) => {
      if (from.name === 'PoPriceAdjustmentBatchAdd' && to.name === 'PoPriceAdjustmentAdd' || from.name === 'PoPriceAdjustmentAdd' && to.name === 'PoPriceAdjustmentBatchAdd') {
        console.log('save')
      } else {
        sessionStorage.removeItem('currentAdjustData')
      }
      next()
    })
    const { type, changePriceBillCode } = this.$route.query
    if (type && type !== 'add') {
      // 整单明细
      this.showWholeDetail(changePriceBillCode)
    }
    if (type && type === 'add') {
      const currentAdjustData = JSON.parse(sessionStorage.getItem('currentAdjustData'))
      Object.assign(this.supplierform, currentAdjustData,)
    }
    if (!this.$route.query.type) {
      // 点击面包屑 从缓存拿数据
      const currentAdjustData = JSON.parse(sessionStorage.getItem('currentAdjustData'))
      Object.assign(this.supplierform, currentAdjustData)
    }
  },
  methods: {
    selectAll(val) {
      if (val.length) {
        this.handleChecked(val, true)
      } else {
        this.handleChecked(this.supplierform.changePriceBillOrderList, false)
      }
    },
    handleChecked(array, boolFlag) {
      array.map(item => {
        this.$set(item, 'checked', boolFlag)
        item.changePriceBillOrderDetailSaveDtoList.map(e => {
          this.$set(e, 'checked', boolFlag)
          this.$nextTick(() => {
            this.$refs.multipleTable.$refs['tableChild' + item.purchaseOrderCode ] && this.$refs.multipleTable.$refs['tableChild' + item.purchaseOrderCode ].toggleRowSelection(
              e,
              boolFlag
            )
          })
        })
      })
    },
    handleTableExpandChange(row) {
      if (row.checked) {
        row.changePriceBillOrderDetailSaveDtoList.map((e) => {
          this.$nextTick(() => {
            this.$refs.multipleTable.$refs['tableChild' + row.purchaseOrderCode ] && this.$refs.multipleTable.$refs['tableChild' + row.purchaseOrderCode ].toggleRowSelection(
              e,
              true
            )
          })
        })
      }
    },
    handleChangeAfterHaveTaxPrice(val, row) {
      const changeRange = FloatSub(val, row.changeBeforeHaveTaxPrice)
      this.$set(row, 'changeRange', row.changeAfterHaveTaxPrice ? (changeRange < 0 ? '减少' : '增加') + Math.abs(changeRange) : '')
    },
    handleSamePrice(row) {
      const { changeAfterHaveTaxPrice, changeBeforeHaveTaxPrice } = row
      if (Number(FloatSub(changeBeforeHaveTaxPrice, changeAfterHaveTaxPrice)) === 0) {
        this.$message.warning('调整后单价不能与调整前单价相等')
        this.$set(row, 'changeAfterHaveTaxPrice', undefined)
        return
      }
    },
    handleOneClick(val) {
      this.supplierform.changePriceBillOrderList = val
    },
    select({ row, rows }) {
      const checked = rows.some(item => item.purchaseOrderCode === row.purchaseOrderCode)
      this.supplierform.changePriceBillOrderList.map((item, index) => {
        if (item.purchaseOrderCode === row.purchaseOrderCode) {
          this.$set(item, 'checked', checked)
          //  找到当前行 给expandData设置checked 并且全勾上
          item.changePriceBillOrderDetailSaveDtoList.map((e) => {
            this.$set(e, 'checked', checked)
            this.$nextTick(() => {
              this.$refs.multipleTable.$refs['tableChild' + row.purchaseOrderCode ] && this.$refs.multipleTable.$refs['tableChild' + row.purchaseOrderCode ].toggleRowSelection(
                e,
                checked
              )
            })
          })
        }
      })
      // console.log(this.supplierform.changePriceBillOrderList)
    },
    selectExpandData({ row, rows }) {
      const checked = rows.some(item => item.deleteKey === row.deleteKey)
      this.supplierform.changePriceBillOrderList.map(item => {
        const obj = item.changePriceBillOrderDetailSaveDtoList.find(ee => ee.deleteKey === row.deleteKey)
        obj && this.$set(obj, 'checked', checked)
      }
      )
    },
    handleDeleteSure() {
      this.multipleSelectExpandTable.map(item => {
        const changePriceBillOrderDetailSaveDtoList = this.supplierform.changePriceBillOrderList.find(e =>
          e.purchaseOrderCode === item.purchaseOrderCode)?.changePriceBillOrderDetailSaveDtoList

        const index = changePriceBillOrderDetailSaveDtoList.findIndex(e => e.specificationModel === item.specificationModel)
        index !== -1 && changePriceBillOrderDetailSaveDtoList.splice(index, 1)
        if (!changePriceBillOrderDetailSaveDtoList.length) {
          const poIndex = this.supplierform.changePriceBillOrderList.findIndex(e => e.purchaseOrderCode === item.purchaseOrderCode)
          this.supplierform.changePriceBillOrderList.splice(poIndex, 1)
        }
      })
      this.multipleSelectExpandTable = []
      this.$forceUpdate()
    },
    handleDelete() {
      this.multipleSelectExpandTable = this.supplierform.changePriceBillOrderList.map(item => {
        return item.changePriceBillOrderDetailSaveDtoList.reduce((acc, cur) => {
          cur.checked && acc.push(cur)
          return acc
        }, [])
      }).flat()
      // 遍历拿到所有checked=true的明细
      const wholeItem = this.supplierform.changePriceBillOrderList.some(item => item.changePriceBillOrderDetailSaveDtoList.every(e => e.checked))
      if (this.multipleSelectExpandTable.length) {
        if (wholeItem) {
          this.$confirm('此操作将删除选中数据, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.handleDeleteSure()
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            })
          })
        } else {
          this.handleDeleteSure()
        }
      } else {
        this.$message.warning('暂无数据删除')
      }
    },
    handleValidate() {
      let flag = true
      this.supplierform.changePriceBillOrderList.map(item => {
        if (item.changePriceBillOrderDetailSaveDtoList.some(e => !e.changeAfterHaveTaxPrice)) {
          flag = false
        }
      })
      return flag
    },
    onSubmit(flag) {
      this.$refs.supplierform.validate(async valid => {
        if (valid && this.handleValidate()) {
          try {
            this.submitLoading = true
            this.flag = flag
            this.supplierform.changePriceBillOrderList = this.supplierform.changePriceBillOrderList.map(item => {
              return {
                ...item,
                changePriceBillOrderDetailSaveDtoList: item.changePriceBillOrderDetailSaveDtoList.map(e => { return { ...e, purchaseOrderCode: item.purchaseOrderCode } })
              }
            })
            const { code, msg } = await this.api(this.supplierform)
            if (code === 0) {
              this.$notify({
                message: msg,
                type: 'success'
              })
              this.$router.go(-1)
            }
          } finally {
            this.submitLoading = false
          }
        } else {
          this.$message.warning('调整后单价（含税)必填')
        }
      })
    },
    async showWholeDetail(changePriceBillCode) {
      const { datas, datas: { changePriceBillOrderList }} = await getChangePriceBillInfo({ changePriceBillCode })
      this.supplierform = datas
      this.supplierform.changePriceBillOrderList = changePriceBillOrderList.map(item => {
        return {
          ...item,
          changePriceBillOrderDetailSaveDtoList: item.changePriceBillOrderDetailSaveDtoList.map(e => {
            const deleteKey = `${e.purchaseOrderCode}${e.style}${e.color}${e.size}`
            return { ...e, deleteKey }
          })
        }
      })
      const currentAdjustData = JSON.parse(sessionStorage.getItem('currentAdjustData'))
      Object.assign(this.supplierform, currentAdjustData)
    }

  }
}
</script>

<style>
.po-style {
  background-color: #f5f7fa;
  color: #C0C4CC;
  border-color: #dfe4ed;
  color: #c0c4cc;
  min-height:40px;
  cursor: not-allowed;
  padding: 0 15px;

}
</style>
